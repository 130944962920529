<template>
    <div>
        <span class="inline-block w-full rounded-md shadow-sm">
            <button
                @click="(evt) => !locked && openSelectMenu(evt)"
                type="button"
                class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-8 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                :class="{
                    'disabled': locked,
                }"
                :aria-label="locked ? 'Runtime locked. Reset to change.' : false"
                data-balloon-pos="down">
                <div class="flex items-center space-x-2">
                    <component class="flex-shrink-0 h-6" :is="`${projectRuntime}Svg`" />
                    <span class="block truncate">{{ projectRuntime }}</span>
                </div>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-600 pointer-events-none">
                    <lockSvg v-if="locked" class="h-4 fill-current" />
                    <svg v-else class="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </button>
        </span>

        <div
            ref="selectMenu"
            class="select-menu absolute mt-1 w-full rounded-md bg-white shadow-lg">
            <ul role="listbox" class="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                <li
                    v-for="(runtime, idx) in runtimes"
                    @click="() => $emit('change-runtime', runtime.name) && closeSelectMenu(false)"
                    :key="runtime.name"
                    :id="`listbox-item-${idx}`"
                    role="option"
                    class="text-gray-900 cursor-default select-none hover:bg-gray-200 relative py-1 pl-3 pr-9">
                    <div class="flex items-center space-x-2">
                        <component class="flex-shrink-0 h-6" :is="`${runtime.name}Svg`" />
                        <span
                            class="block truncate"
                            :class="{
                                'font-normal': runtime.name !== projectRuntime,
                                'font-semibold': runtime.name === projectRuntime,
                            }"
                        >{{ runtime.name }}</span>
                    </div>

                    <span
                        v-if="runtime.name === projectRuntime"
                        class="absolute inset-y-0 right-0 flex items-center pr-4">
                        <doneSvg class="h-4 fill-current" />
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TutorialRuntime',

    props: {
        runtimes: {
            type: Array,
            required: true,
        },

        projectRuntime: {
            type: String,
            required: true,
        },

        locked: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectMenuOpen: false,
        };
    },

    methods: {
        afterCloseSelectMenu() {
            document.removeEventListener('click', this.closeSelectMenu);
            this.selectMenuOpen = false;
        },

        closeSelectMenu(evt) {
            if (evt !== false) {
                try {
                    if (this.$refs.selectMenu.contains(evt.srcElement)) return;
                } catch (ex) { return; }
            }
            this.$refs.selectMenu.classList.remove('active');
            this.afterCloseSelectMenu();
        },

        openSelectMenu(evt) {
            evt.stopImmediatePropagation();
            if (this.selectMenuOpen) return;
            this.$refs.selectMenu.classList.add('active');
            document.addEventListener('click', this.closeSelectMenu);
            this.selectMenuOpen = true;
        },
    },

    components: {
        lockSvg: () => import('@/assets/img/lock.svg'),
        doneSvg: () => import('@/assets/img/done.svg'),
        phpSvg: () => import('@/assets/img/php.svg'),
        javaSvg: () => import('@/assets/img/java.svg'),
        nodeSvg: () => import('@/assets/img/node.svg'),
        pythonSvg: () => import('@/assets/img/python.svg'),
        rubySvg: () => import('@/assets/img/ruby.svg'),
        pioSvg: () => import('@/assets/img/pio.svg'),
        vscodeSvg: () => import('@/assets/img/vscode.svg'),
    },
};
</script>

<style lang="postcss" scoped>
.select-menu {
    opacity: 0;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: auto;
    }
}
</style>
